import React, { useState, useEffect } from 'react';
import NextStep from '../common/NextStep';
import { dataAgentAPI } from '../../actions/dataAgent';

import { HStack, Loading, Modal, Text } from '@arcault-ui/shared-components'


const BASIC_INFO = 'welcome';
dataAgentAPI.modifyJourney(BASIC_INFO);

const BasicInfo = ({ isOpen, setBasicInfoNeeded }) => {
  const [nextStep, setNextStep] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const checkAndRunComplete = (stepId) => {
    if (stepId === 'available_journeys') {
        setBasicInfoNeeded(false);
    }
  }

  const getDataAgent = async (journeyName) => {
    try {
      const nextData = await dataAgentAPI.get()

      console.log('basic nextData', nextData);
      
      checkAndRunComplete(nextData.data.stepId)
      setNextStep(nextData.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to load user journey:", error);
    }
  }

  useEffect(() => {
    getDataAgent(BASIC_INFO);
  }, []);

  const saveData = async (propertyValue, step) => {
    const payload = {
        ...step,
        stepId: nextStep.stepId,
        propertyName: nextStep.propertyName,
        propertyValue
    };

    if (step.type === 'ai-generated') {
      payload.ai = true;
    }

    const savedData = await dataAgentAPI.save(payload);
    setNextStep(savedData.data);
    checkAndRunComplete(savedData.data.stepId)
    return savedData;
  }

    return (
        <Modal stayOpen isOpen={isOpen} size="xl" className="basic-info-modal" title={(
            <HStack className="basic-info-title"><img style={{ width: 30 }} src="logo_flame.png" alt="overview" className="overview-image" /> <Text variant="h2">Welcome to Arcault</Text></HStack>
        )}>     
            <>
                <Text variant="body">
                    Let's get started! Some basic information that will allow us to better advise you in all facets of life-planning.  This phase typically takes <em>less than a minute.</em>
                </Text>
                <div className="dashboard-next-step-container">
                    {isLoading && (
                        <Loading />
                    )}
                    {!isLoading && (
                        <NextStep nextStep={nextStep} setNextStep={setNextStep} saveData={saveData}/>
                    )}
                </div>
            </>
        </Modal>
    )
}

export default BasicInfo;
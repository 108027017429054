// src/App.js
import React from 'react';

import AppRoutes from './AppRoutes';
import {BrowserRouter } from 'react-router-dom'
import { SettingsProvider } from './context/SettingsContext';  // Only wrap AppRoutes now
import { MessageProvider } from './context/MessageContext';  // Only wrap AppRoutes now
import { HelpProvider } from './context/HelpContext';

import './App.css';

function App() {
  return (
    <BrowserRouter>
        <SettingsProvider>
          <MessageProvider>
            <HelpProvider>
              <AppRoutes />
            </HelpProvider>
          </MessageProvider>
        </SettingsProvider>
    </BrowserRouter>
  );
}

export default App;

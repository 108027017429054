import Base from './base';

const BASE_URL = '/ajourney/journey';

class Journey extends Base {
    modifyJourney(journey) {
        this.base_url = BASE_URL + '/' + journey;
    }

}

export const journeyAPI = new Journey(BASE_URL);

import { useState, useEffect } from 'react';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';
import { Card } from '@arcault-ui/shared-components';
// import InfoAssetsTable from '../common/assetsTable/AssetsTable';
import InfoAssetsTable from '../common/infoAssets/InfoAssetsTable';
import './Assets.css';
import { Tab, TabList, TabPanel } from '../common/Tabs';


export const assetFields = {
  /*initialFields: [
    { id: 'service', type: 'autocomplete', suggestions: ['Asset', 'Liability'], label: 'Asset or Liability', placeholder: 'Asset or Liability?', column: true, form: true},  
  ],*/
  assetFields: [
    { id: 'assetType', type: 'autocomplete', suggestions: ['House', 'Car', 'Boat', 'Collection', 'Other'], label: 'Asset Type',column: true, form: true},
  ],
  commonFields: [
    { id: 'description', label: 'Description',column: true, form: true, rank: 1, fullWidth: true},
    { id: 'tags', label: 'Tags',column: true, form: true, rank: 50},
    { id: 'estimatedValue', label: 'Estimated Value',column: true, form: true, rank: 100}
  ],
  extraFields: {
    Car: [
      { id: 'make', label: 'Make' },
      { id: 'model', label: 'Model' },
      { id: 'year', label: 'Year' }
    ],
    House: [
      { id: 'address', label: 'Address', fullWidth: true},
      { id: 'size', label: 'Size (sqft)' },
      { id: 'lot', label: 'Lot Size (acres)' },
      { id: 'bedrooms', label: 'Bedrooms' },
      { id: 'baths', label: 'Baths' },
    ],
    Collection: [
      { id: 'items', label: 'Items' }
    ],
    financial: [
      { id: 'accountNumber', label: 'Account Number' }
    ],
  }
}


export const handleDynamicFields = (formData, setFields, isAsset) => {
  console.log('assets', isAsset, formData);
  let newFields = [];
  if (isAsset) {
    newFields = [...newFields, ...assetFields.assetFields];
    if (formData.assetType) newFields = [...newFields, ...assetFields.commonFields, ...assetFields.extraFields[formData.assetType] || []];
  } else {
    newFields = [...newFields, ...assetFields.commonFields]
  }
  setFields(newFields);
}

const Assets = () => {
  
  const axiosWithCredentials = useAxiosWithCredentials()  
  const [assets, setAssets] = useState([]);
  const [fields, setFields] = useState([...assetFields.assetFields, ...assetFields.commonFields]);
  const [activeTab, setActiveTab] = useState('Assets');


  const handleFormChangeCallback = (isAsset) => (formData) => {
    handleDynamicFields(formData, setFields, isAsset);
  }

  /*const onAdd = (service) => async (formData) => {
    try {
      const response = await axiosWithCredentials.post(`/fo/${service}/info`, formData);
      if (response.status === 200 || response.status === 201) console.log('Assets post successful');
    } catch (error) {
      console.error('Error during Update', error);
    }
  }

  const onEdit = (service) => async (formData) => {
    try {
      const response = await axiosWithCredentials.post(`/fo/${service}/info/${formData.id}`, formData);
      if (response.status === 200 || response.status === 201) console.log('Assets post successful');
    } catch (error) {
      console.error('Error during Update', error);
    }
  }*/
  
  // const loadAssets = async () => {
  //   try {
  //     const response = await axiosWithCredentials.get('/fo/assets');
  //     return response.data.Items; 
  //   } catch (error) {
  //     console.error("Error loading assets:", error);
  //     // Handle errors appropriately
  //   }
  // };
  
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const data = await loadAssets();
  //     if (data) {
  //       setAssets(data);
  //     }
  //   };

  //   fetchData();
  // }, []);


  return (
    <Card className="card-no-shadow dashboard">
            <TabList>
        <Tab activeTab={activeTab} label="Assets" onClick={setActiveTab}>
          Assets
        </Tab>
        <Tab activeTab={activeTab} label="Liabilities" onClick={setActiveTab}>
          Liabilities
        </Tab>
      </TabList>

      <TabPanel activeTab={activeTab} label="Assets">
        <InfoAssetsTable
          title="Assets"
          addTitle="Add Asset +"
          dialogTitle="Asset"
          tableFields={assetFields.commonFields}
          fields={fields}
          handleFormChangeCallback={handleFormChangeCallback(true)}
          service="assets"
          data={assets} // Pass the assets data to the table
        />
      </TabPanel>
      <TabPanel activeTab={activeTab} label="Liabilities">
        <InfoAssetsTable
          title="Liabilities"
          addTitle="Add Liability +"
          dialogTitle="Liability"
          tableFields={assetFields.commonFields}
          fields={fields}
          handleFormChangeCallback={handleFormChangeCallback(false)}
          service="liabilities"
          data={assets} // Pass the liabilities data to the table
        />
      </TabPanel>
    </Card>  
  );
}
export default Assets
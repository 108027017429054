import React, { useEffect, useState } from 'react';

import { Button, Card, Stack, Text } from '@arcault-ui/shared-components'
import { useNavigate } from 'react-router-dom';
import { profileAPI } from '../../actions/profile';

const JourneyOption = ({ title, icon, location, active, recommended }) => {
    const navigate = useNavigate();

    return (
        <Card className={`neu-card journey-choice ${active ? 'journey-choice-active' : 'journey-choice-inactive'} ${recommended ? 'journey-choice-recommended' : ''}`}  title={title}
        footer={active ? (
                <Button type={recommended ? 'accent' : 'outline'} onClick={() => navigate('/' + location)}>Let's Go</Button>
            ) : (
                <Text variant="body-large">Coming soon!</Text>
            )}>
            <Stack variant="center">
            <div className="journey-choice-icon">
                <img src={`/${icon}_icon.png`} alt="Estate Planning" />
            </div>
            </Stack>
        </Card>
    )
}


const JourneySelector = () => {
    const [firstName, setFirstName] = useState(false);

    useEffect(() => {
        profileAPI.get().then((profile) => {
            console.log('profile', profile);
            setFirstName(profile.data.firstName);
        });

    }, []);
    
    return (
        <div className="main-dashboard-card">
            <Text variant="h2">{firstName ? `Welcome ${firstName}!` : 'Welcome '}</Text>
            <Text variant="h3">How do you want to build your future today?</Text>
            <Text variant="body">Arcault connects you to innovative tools and professional guidance so you can build comprehensive life plans that secure your future, protect your family and define your legacy. <b>Where should we start?</b></Text>
            <div className="choices-container">
                <JourneyOption title="P&C Insurance" icon="insurance" location="insurance" active recommended/>
                <JourneyOption title="Estate Planning" icon="estate_planning" location="estate" active />
                <JourneyOption title="Taxes" icon="taxes" location="taxes" />
            </div>
        </div>
    );
}

export default JourneySelector;
import setupAxiosWithCredentials from "../utils/setupAxiosWithCredentials";
import { axiosWithCredentials } from "../api/axios";

class BASE_API {
    constructor(base) {
        this.base_url = base;
    }

    async save(payload) {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const data = await axiosWithCredentials.post(this.base_url, payload);
            return data;
        } finally {
            removeInterceptors();
        }
    }

    async get(id) {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const data = id ? 
                await axiosWithCredentials.get(this.base_url + '/' + id) : 
                await axiosWithCredentials.get(this.base_url)
            return data;
        } finally {
            removeInterceptors();
        }
    }

    async edit(id) {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const data = await axiosWithCredentials.put(this.base_url + '/' + id);
            return data;
        } finally {
            removeInterceptors();
        }
    }

    async delete(id) {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const data = await axiosWithCredentials.delete(this.base_url + '/' + id);
            return data;
        } finally {
            removeInterceptors();
        }
    }
}

export default BASE_API;


import React, { useState } from 'react';
import { Avatar, Button, Card, HStack, Stack, Text } from '@arcault-ui/shared-components';
import './TeamViewer.css';
import AddTeamMember from './AddTeamMember';


const Team = ({ fetchContacts, navigate, contacts, rolesConfig }) => {
  const [openAddMember, setOpenAddMember] = useState(false);
  const [openRole, setOpenRole] = useState(null);

  console.log('contacts', contacts);

  const matchedRoles = (rolesConfig?.items || []).reduce((acc, role) => {
    console.log('contacts', contacts);
    const matchedContacts = (contacts || []).filter(contact => contact.tags?.includes(role.tag));
    console.log('matchedContacts', contacts, role.tag, matchedContacts);
//    if (matchedContacts.length || role.multiple) {
      acc.push({ role, contacts: matchedContacts });
 //   }
    return acc;
  }, []).slice(0, 6);

  return (
    <Card title="Team" className="team-container your-vault-card" footer={(
      <HStack variant="end"><Button onClick={() => navigate('/contacts')} type="outline">View all</Button></HStack>
    )}>
      <div className="mobile-team-viewer">
        <Text variant="h4">Your Team</Text>
        <Text variant="h4">{matchedRoles.length} contacts</Text>
      </div>
      <AddTeamMember fetchContacts={fetchContacts} rolesConfig={rolesConfig} currentRole={openRole} isOpen={openAddMember} handleClose={setOpenAddMember}/>
      <Stack className="team-viewer-inner" variant="stretch">
        <div className="team-members">
          {matchedRoles.map(({ role, contacts }) => (
            contacts.length > 0 ? contacts.map(contact => (
              <Card title={(
                  <Stack>
                    <Text variant="caption">{role.label}</Text>
                    <Text variant="body">{contact?.givenName} {contact?.familyName}</Text>
                  </Stack>
                )} key={contact.id} className="neu-card-low team-member">
                <Stack variant="stretch">
                  <Avatar user={{
                    ...contact,
                  }} />
                </Stack>
              </Card>
            )) : (
              <Card 
                key={role.name} 
                onClick={() => {
                  setOpenRole(role.tag)
                  setOpenAddMember(true)
                }}
                title={role.label || ' '}
                className="team-member ghost dot"
              >
                <Stack variant="stretch">
                  <Avatar user={{}} />
                </Stack>
              </Card>
            )
          ))}
          <Card key="other" onClick={() => {
            setOpenRole('estate-planning-team')
            setOpenAddMember(true)
          }} className="team-member outline">
                <Stack variant="stretch">
                  <div className="role-label"><Text variant="caption">Other team member</Text></div>
                  <Avatar user={{}} />
                </Stack>
              </Card>
        </div>
      </Stack>
    </Card>
  );
};

Team.propTypes = {
  // If there are additional props we need to accept, we should define them here.
};

export default Team;
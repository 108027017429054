import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Autocomplete,
    Avatar,
    Text,
    Button,
    ListItem,
    HStack,
    Modal,
    Stack,
} from '@arcault-ui/shared-components';  
import useAxiosWithCredentials from '../../../hooks/useAxiosWithCredentials';
import { Textarea } from '@chakra-ui/react';
import useDocuments from '../../../hooks/useDocuments';
import { contactsAPI } from '../../../actions/contacts';

const EditRoleDialog = ({
  isOpen,
  handleClose,
  currentRole,
  rolesConfig,
  fetchContacts,
}) => {
    const [roles, setRoles] = useState({});
    const [candidates, setCandidates] = useState([]);  
    const [selectedContact, setSelectedContact] = useState(null);
    const [originalContacts, setOriginalContacts] = useState([]);

    const {
      DropArea,
      UploadMechanism,
  } = useDocuments({ initialDocuments: [], category: 'add-contact' });


    const [tempContacts, setTempContacts] = useState([]);
    const axiosWithCredentials = useAxiosWithCredentials();
    console.log('isopen', isOpen);

  useEffect(() => {
    const fetchCandidates = async () => {
      try {

        const response = await contactsAPI.get();
        const loadedContacts = response?.data || [];
        console.log('loadedContacts', loadedContacts);
        setCandidates(loadedContacts);

        // Initialize roles state based on rolesConfig and loaded contacts
        const initialRoles = {};
        (rolesConfig?.items || []).forEach(role => {
          initialRoles[role.name] = {
            contacts: (loadedContacts || []).filter(contact => contact.tags.includes(role.name)),
            notes: ''
          };
        });
        setRoles(initialRoles);
      } catch (error) {
        console.error('Failed to fetch candidates:', error);
      }
    };

    fetchCandidates();
  }, [rolesConfig, axiosWithCredentials]);

  const handleAddContact = (contact) => {
    setTempContacts(prevContacts => [...prevContacts, contact]);
    setSelectedContact(null);
  };

  const handleRemoveContact = (contactId) => {
    setTempContacts(prevContacts => prevContacts.filter(contact => contact.contactId !== contactId));
  };

  const addTag = async (contact, tag) => {
    try {
      await axiosWithCredentials.post(`/fo/contacts/${contact.type}/${contact.id}/tag/${tag}`);
    } catch (error) {
      console.error(`Failed to add tag ${tag} to contact ${contact.id}:`, error);
    }
  };

  const removeTag = async (contact, tag) => {
    try {
      await axiosWithCredentials.delete(`/fo/contacts/${contact.type}/${contact.id}/tag/${tag}`);
    } catch (error) {
      console.error(`Failed to remove tag ${tag} from contact ${contact.id}:`, error);
    }
  };

  const handleSave = async () => {
    const contactsToAdd = tempContacts.filter(contact => !originalContacts.some(orig => orig.contactId === contact.contactId));
    const contactsToRemove = originalContacts.filter(contact => !tempContacts.some(temp => temp.contactId === contact.contactId));

    for (const contact of contactsToAdd) {
      await addTag(contact, currentRole);
    }

    for (const contact of contactsToRemove) {
      await removeTag(contact, currentRole);
    }

    setRoles(prevRoles => ({
      ...prevRoles,
      [currentRole]: { ...prevRoles[currentRole], contacts: tempContacts }
    }));

    fetchContacts();
    handleClose(false);
  };

  if (!isOpen) return null;

  // Find the current role's label safely
  const currentRoleLabel = (rolesConfig?.items || []).find(role => role.name === currentRole)?.label || 'Contact';

  return (
    <Modal isOpen={isOpen} title={`Add ${currentRoleLabel}`}>
        <Autocomplete 
            label="Find Contact"
            className="search-contacts"
            placeholder="Search Contacts"
            suggestions={candidates.map(m => `${m.givenName} ${m.familyName}`)}
            onChange={(e) => {
                setSelectedContact(candidates.find(m => `${m.givenName} ${m.familyName}` === e.target.value));
            }}
        />
        <div style={{ paddingBottom: 4 }}>
          <Text variant="caption">
            If you cannot find the person you are looking for, please go to your <a href="/profile/contacts">Contacts</a> section and add them.
          </Text>
        </div>
        <HStack variant="end">
          <Button
            onClick={() => handleAddContact(selectedContact)}
            disabled={!selectedContact}
            type="primary"
          >
            Select Contact
          </Button>
        </HStack>
        {/*<Text variant="h6">Assigned Contacts</Text>*/}
        <div className="selected-stack">
          {tempContacts.map(contact => (
            <ListItem key={contact.contactId}>
              <HStack variant="spread">
                <HStack className="add-team-member-list" variant="start">
                  <Avatar className="add-team-member-list-contact" user={contact} />
                    <Stack>
                      <Text variant="body-large">{contact.givenName} {contact.familyName}</Text>
                      <Text variant="caption">{contact.email}</Text>
                    </Stack>
                </HStack>
                <Button onClick={() => handleRemoveContact(contact.contactId)}>Remove</Button>
              </HStack>
              <DropArea><Textarea placeholder="Letter of Intent, type here or upload."/></DropArea>
            </ListItem>
          ))}
        </div>
      <HStack variant="spread">
        <Button type="outline" onClick={() => handleClose(false)}>Cancel</Button>
        <Button type="primary" onClick={handleSave}>Save</Button>
      </HStack>
      <div>&nbsp;</div>
    </Modal>
  );
};

// PropTypes for type-checking
EditRoleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  currentRole: PropTypes.string.isRequired,
  rolesConfig: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  candidates: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  })).isRequired,
  tempContacts: PropTypes.arrayOf(PropTypes.shape({
    contactId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  })).isRequired,
  handleAddContact: PropTypes.func.isRequired,
  handleRemoveContact: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default EditRoleDialog;
import React, { useRef, useEffect, useState } from 'react';
import Header from './Header';
import LeftBarMain from './LeftBarMain';
import LeftBarProfile from './LeftBarProfile';
import Drawer from './Drawer';

import { Outlet, useLocation } from 'react-router-dom'

import { useHelp } from '../../context/HelpContext';
import { SearchBar, Icon, Text, HStack } from '@arcault-ui/shared-components';

import { IconButton } from '@mui/material';

const Layout = ({ signOut, user }) => {
  const location = useLocation();
  const isProfileNav = location.pathname.includes('/profile');

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', flex: 1 }}>
      <div className="background-base" />

      <div className="background-layer" />
      <Header isProfileNav={isProfileNav} signOut={signOut} user={user} />
      <div style={{ flex: 1, display: 'flex' }}>
        {isProfileNav && <LeftBarProfile className="desktop-nav" />}
        {!isProfileNav && <LeftBarMain className="desktop-nav" /> }
        
        <div className="main-content">
          <Outlet />
        </div>
      </div>
      <HelpComponent />
    </div>
  )
}

const HelpComponent = () => {
  const { isHelpOpen, setIsHelpOpen, helpPrompt } = useHelp();
  const [chatInput, setChatInput] = useState('');
  const searchBarRef = useRef(null);

  useEffect(() => {
    if (isHelpOpen && searchBarRef.current) {
      searchBarRef.current.focus();
    }
  }, [isHelpOpen]);

  return (
    <Drawer isOpen={isHelpOpen}>
      <div className="drawer-header">
        <HStack variant="spread">
          <Text variant="h3">Your Assistant</Text>
          <IconButton onClick={() => setIsHelpOpen(false)}><Icon name="close" /></IconButton>
        </HStack>
      </div>
      <Text variant="body">{helpPrompt}</Text>

      <SearchBar value={chatInput} onChange={(e) => setChatInput(e.target.value)} ref={searchBarRef} autoFocus={isHelpOpen} placeholder="Questions?" />
    </Drawer>
  );
};

export default Layout;
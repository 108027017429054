import React, {useState, useEffect}from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, HStack, Stack, Text } from  '@arcault-ui/shared-components';
import { assetsAPI } from '../../../actions/assets';
import { liabilitiesAPI } from '../../../actions/liabilities';
import { contactsAPI } from '../../../actions/contacts';
import ProposalInbox from './ProposalInbox'
import Inbox from './Inbox'
import DocumentsViewer from '../dashboard/DocumentsViewer';

import '../dashboard/Dashboard.css';
import EstateValueCard from '../dashboard/EstateValue';

const Dashboard = ({ rolesConfig, title, journey, final_image, professionalName, offers }) => {
  const [contacts, setContacts] = useState([]);
  const [accepted, setAccepted] = useState(false);
  const [assets, setAssets] = useState([]);
  const [liabilities, setLiabilities] = useState([]);
  const [requiredDocuments, setRequiredDocuments] = useState([]);

  console.log('offers', offers);

  useEffect(() => {
    fetchJourney();
    loadAssociatedData();
  }, []);
  

  const fetchAssets = async () => {
    try {
      const response = await assetsAPI.get();
      setAssets(response.data);
    } catch (error) {
      console.error("Failed to load contacts:", error);
    }
  };

  const fetchLiabilities = async () => {
    try {
      const response = await liabilitiesAPI.get();
      setLiabilities(response.data);
    } catch (error) {
      console.error("Failed to load contacts:", error);
    }
  };


  const fetchContacts = async () => {
    try {
      const response = await contactsAPI.get();
      setContacts(response.data);
    } catch (error) {
      console.error("Failed to load contacts:", error);
    }
  };

  const fetchJourney = async () => {
    // https://api.dev.arcault.com/ajourney/model/estate_planning
    try {
      const response = {
        "info": {
          "completedSteps": [],
          "aiOmittedSteps": [],
          "progress": 0,
          "documents": {
              "funeralPlan": {
                  "description": "A document that specifies your preferences for funeral arrangements, burial, or cremation. It can also include financial considerations or prepayment information.",
                  "label": "Funeral Plan",
                  "notes": "Provides peace of mind and clarity for your family during an emotional time.",
                  "ai-draftable": true
              },
              "livingWill": {
                  "description": "A document that outlines your wishes for medical treatment if you become unable to communicate. It often works in conjunction with a health care proxy.",
                  "label": "Living Will",
                  "notes": "Provides clarity for medical providers and family members during critical situations.",
                  "ai-draftable": false
              },
              "will": {
                  "description": "A legal document that specifies how your assets and property will be distributed upon your death. It can also designate guardians for minor children and outline your final wishes.",
                  "label": "Last Will & Testament",
                  "notes": "Essential for ensuring your estate is handled according to your wishes.",
                  "ai-draftable": false
              },
              "guardianship": {
                  "description": "A document that specifies who will care for your minor children or dependents if you are unable to do so. This can be included in your will or created as a standalone document.",
                  "label": "Guardianship Designation",
                  "notes": "Important for parents and caregivers to provide clarity and security for their dependents.",
                  "ai-draftable": false
              },
              "livingTrust": {
                  "description": "A document that places your assets in a trust during your lifetime and specifies how they will be distributed after your death. It helps avoid probate and provides privacy for your estate.",
                  "label": "Living Trust",
                  "notes": "Recommended for individuals with significant or complex assets.",
                  "ai-draftable": false
              },
              "ha": {
                  "description": "A document that appoints someone to make healthcare decisions on your behalf if you are unable to do so. It may include your preferences for medical treatments and end-of-life care.",
                  "label": "Health Care Proxy / Advance Directive",
                  "notes": "Critical for ensuring your healthcare preferences are followed during a medical crisis.",
                  "ai-draftable": false
              },
              "digitalAssetPlan": {
                  "description": "A document that outlines how your digital assets—such as email accounts, social media profiles, and online financial accounts—should be managed or transferred after your death.",
                  "label": "Digital Asset Plan",
                  "notes": "Increasingly important in the digital age for securing online information.",
                  "ai-draftable": true
              },
              "fpoa": {
                  "description": "A document that authorizes someone to manage your financial affairs if you are incapacitated or unavailable. It can cover tasks such as paying bills, managing investments, and handling real estate transactions.",
                  "label": "Financial Power of Attorney",
                  "notes": "Ensures your financial matters are managed by a trusted person when needed.",
                  "ai-draftable": false
              },
              "letterOfIntent": {
                  "description": "A non-binding document that provides additional instructions or context for your estate plan. It can include your values, personal messages, or explanations for your decisions.",
                  "label": "Letter of Intent",
                  "notes": "Helps guide family members and executors with your personal wishes.",
                  "ai-draftable": true
              }
            }
          }
      }
      // await axiosWithCredentials.get('/ajourney/journey/estate_planning');
      console.log('response journey', response);
      setRequiredDocuments(response?.info?.documents)
    } catch (error) {
      console.error("Failed to load journey:", error);
    }
  }

  const loadAssociatedData = () => {
    fetchContacts();
    fetchLiabilities();
    fetchAssets();
  }
    
  const navigate = useNavigate();
  return (
    <Card className="card-dashboard">
      <HStack>
        <Stack>
          <Text variant="h1" className="dashboard-title">{title}</Text>
          {!accepted && <ProposalInbox offers={offers}/>}
          {accepted && <Inbox contacts={contacts} />}
        </Stack>
        <Stack className="inbox-dashboard-right">
          <EstateValueCard assets={assets} liabilities={liabilities} navigate={navigate} />
          <DocumentsViewer journey={journey} requiredDocuments={requiredDocuments} navigate={navigate}/>
        </Stack>
      </HStack>
    </Card>
  );
}

export default Dashboard;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Text, Card, Icon, Stack, Button, HStack } from  '@arcault-ui/shared-components';
import useAxiosWithCredentials from '../../../hooks/useAxiosWithCredentials';
import './DocumentsViewer.css';
import useDocuments from '../../../hooks/useDocuments';
import { filesAPI } from '../../../actions/files';

const NeededDocument = ({ category, documents, docType, fetchDocuments }) => {
    const axiosWithCredentials = useAxiosWithCredentials();
    const { tag } = docType || {};

    const onAddDocument = async (fileId) => {
        try {
           // console.log('onAddDocument', fileId, docType);
            await axiosWithCredentials.put(`/files/v2/tag/${fileId}/${tag}`);
            await fetchDocuments();
            console.log('fileId', fileId);
          } catch (error) {
            console.error(`Failed to add tag ${category} to file ${fileId}:`, error);
          }  
    };

    const {
        DropArea,
        UploadMechanism,
    } = useDocuments({ initialDocuments: documents, category, addCallBack: onAddDocument });

    return (
        <UploadMechanism>
        <Card key={docType.name} className={`document-item card-no-shadow ${docType.style || 'ghost'} ${docType.required ? 'required-document dot' : 'optional-document'}`}>
    
                <DropArea>
                <Stack variant="stretch" className="document-item-content">
                    <Text variant="caption">{docType.label}</Text>
                    <Icon name="upload" />
                </Stack>
            </DropArea>
            
        </Card>
        </UploadMechanism>
    )
};

const DocCard = ({ data, deleteDocument, fileShareStatus, docType }) => {
    const [downloadUrl, setDownloadUrl] = useState(null);
    console.log('response data', data)
    const handleDelete = (sortKey) => {}
    const toggleFileShare = (sortKey) => {}
    const [superType, type] = data.fileType.split('/');

    const downloadFile = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = ''; // You can set a default filename here if needed
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    
      // Call this function when the download URL is set
      useEffect(() => {
        if (downloadUrl) {
          downloadFile(downloadUrl);
        }
      }, [downloadUrl]);
    
      const handleDownload = async (fileKey) => {
        setDownloadUrl(null);
        try {
          const url = await filesAPI.getUrl(fileKey);
          setDownloadUrl(url);
        } catch (error) {
          console.error('Error downloading the file:', error);
        }
      };
    
    return (
      <Card className="doc-card neu-card-low" title={docType.label} footer={(
        <HStack className="doc-card-actions" variant="spread">
          <Icon onClick={(row) => handleDelete(row.sortKey)} name="delete" />
          <Icon onClick={() => handleDownload(data.s3Key)} name="download" />
          {fileShareStatus && fileShareStatus[data.fileId] && <Icon onClick={(row) => toggleFileShare(row.sortKey)} name="share" />}
          {(!fileShareStatus || !fileShareStatus[data.fileId]) && <Icon onClick={(row) => toggleFileShare(row.sortKey)} name="share" />}
        </HStack>
      )}>
        <div className="doc-icon">
          <Text variant="caption">{type.length > 4 ? 'file' : type}</Text>
          <Icon name="draft" />
        </div>
      </Card>
    )
  }

const DocumentsViewer = ({ journey, requiredDocuments, navigate }) => {

    const [allDocuments, setAllDocuments] = useState([])
    const {
        documents,
        deleteDocument,
        fetchDocuments,
        
    } = useDocuments({ category: journey });

    const loadDocs = async () => {
        setAllDocuments(documents);
    }

    useEffect(() => {
        loadDocs()
    }, [documents]);


  const matchedDocs = Object.keys(requiredDocuments?.items || {}).map(id => ({ ...requiredDocuments?.items[id], id, name: id })).reduce((acc, docType) => {
    const matchedDocuments = allDocuments.filter(doc => doc.tags.includes(docType.tag));
    console.log('all', allDocuments, matchedDocuments)

    acc.push({ docType, documents: matchedDocuments });
    return acc;
  }, []);

  return (
    <Card title="Documents" className="documents-container your-vault-card" footer={(
        <HStack variant="end"><Button onClick={() => navigate('/documents')} type="outline">View all</Button></HStack>
    )}>
        <div className="mobile-doc-viewer">
            <Text variant="h4">Documents</Text>
            <Text variant="h4">{matchedDocs.length} docs</Text>
        </div>
        <Stack variant="stretch">
            <div className="documents-list">    
                {matchedDocs.map(({ docType, documents }) => (
                    documents.length > 0 ? documents.map(doc => ( <>
                        <DocCard data={doc} docType={docType} /></>
                    )) : (
                        <NeededDocument 
                            category={journey} 
                            fetchDocuments={fetchDocuments}
                            documents={documents}
                            docType={{ ...docType, required: true }}
                        />
                    )
                ))}
                <NeededDocument category={journey} documents={documents} docType={{
                    label: 'Other documents',
                    name: journey,
                    style: 'outline',
                    fetchDocuments,
                }}/>

            </div>
        </Stack>
    </Card>
  );
};

DocumentsViewer.propTypes = {
  docsConfig: PropTypes.array.isRequired,
  onAddDocument: PropTypes.func.isRequired,
};

export default DocumentsViewer;
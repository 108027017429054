import React, { useState }from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, HStack, Stack, Text } from  '@arcault-ui/shared-components';
import NextStep from './DashboardNextStep';
import Progress from './DashboardProgress';
import DocumentsViewer from './DocumentsViewer';

import './Dashboard.css';
import EstateValueCard from './EstateValue';
import TeamViewer from './TeamViewer';

const Dashboard = ({
  loadAssociatedData,
  contacts,
  assets,
  liabilities, 
  requiredDocuments,
  rolesConfig,
  title,
  journey,
  final_image,
  professionalName,
  requiredTeam,
  fetchContacts,
}) => {
  const [progress, setProgress] = useState(0);
  const [stage, setStage] = useState('');
    
  const navigate = useNavigate();

  return (
    <Card className="card-dashboard">
      <HStack className="dashboard-planning-content">
        <NextStep title={title} professionalName={professionalName} final_image={final_image} journey={journey} setProgress={setProgress} setStage={setStage} loadAssociatedData={loadAssociatedData} />
        <Progress title={title} journey={journey} progress={Math.floor(progress)} stage={stage}/>
        <Card key="proposal-list" className={`proposal-inbox card-no-shadow proposal-inbox-incomplete`}>
          <div key="header" className="card-header" style={{ display: 'flex'}}>
            <div>Proposals</div>
          </div>
          <div data-testid="help-section-none" className="help-section">
              You don't have any proposals yet.  We need a little more information to connect you with professionals. Check your progress to the left.   
          </div>
        </Card>
        
      </HStack>
      <Stack className="your-vault-content" variant="start">
        <div className="your-vault">
          <Text variant="h4">Your Vault</Text>
        </div>
        <HStack variant="stretch">
          <EstateValueCard assets={assets} liabilities={liabilities} navigate={navigate} loadAssociatedData={loadAssociatedData}/>
          <TeamViewer fetchContacts={fetchContacts} journey={journey} rolesConfig={requiredTeam} contacts={contacts} navigate={navigate} />
          <DocumentsViewer journey={journey} requiredDocuments={requiredDocuments} navigate={navigate}/>
        </HStack>
      </Stack>
    </Card>
  );
}

export default Dashboard;

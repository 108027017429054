
import React from 'react';
import { NavLink } from 'react-router-dom';
import { HStack, Icon, Text, Stack } from '@arcault-ui/shared-components'

// LeftNavMenu.jsx
const menuItems = [
  { url: "/", icon: "cottage", label: "Overview" },
//  { url: "/assistant", icon: "assistant", label: "Personal Assistant" },
  { url: "/estate", icon: "history_edu", label: "Estate Planning" },
 // { url: "/financial", icon: "account_balance", label: "Financial" },
  { url: "/insurance", icon: "health_and_safety", label: "Insurance" },
 // { url: "/taxes", icon: "inventory", label: "Taxes" },
 // { url: "/health", icon: "monitor_heart", label: "Health" },
];

const profileItems = [
  { url: "/contacts", icon: "contacts", label: "Contacts" },
  { url: "/assets", icon: "Money", label: "Assets" },
  { url: "/documents", icon: "Article", label: "Documents" },

]

const LeftNavMenu = ({ className, onSelect }) => {
  return (
    <Stack
      direction="column"
      className={`navigation ${className}`}
    >
      <Stack className="nav-stacks top-nav-stacks" align="center">
        {menuItems.map(({ url, icon, label }) => (
          <NavItem icon={icon} label={label} href={url} onSelect={onSelect}/>
        ))}
      </Stack>
      <Stack className="nav-stacks" align="center">
        {profileItems.map(({ url, icon, label }) => (
          <NavItem icon={icon} label={label} href={url} onSelect={onSelect}/>
        ))}
      </Stack>
    </Stack>
  );
};

const NavItem = ({ icon, label, href, onSelect }) => (
  <NavLink
    to={href}
    onClick={onSelect}
    className="nav-item"
  >
    <HStack
      className="nav-item-inner"
    >
      <Icon name={icon} />
      <Text>{label}</Text>
    </HStack>
  </NavLink>
);

export default LeftNavMenu;
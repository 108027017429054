import React from 'react';
import { Modal, HStack, FormInput, Button, Autocomplete } from '@arcault-ui/shared-components';

import './FormDialog.css';

const FormDialog = ({ open, handleClose, editMode, dialogTitle, fields, formData, handleFormChange, handleSave }) => {
  return (
    <Modal
        isOpen={open}
        onClose={handleClose}
        title={editMode ? `Edit ${dialogTitle}` : `Add ${dialogTitle}`}
        footer={(
            <HStack variant="spread">
                <Button type="outline" onClick={handleClose}>Cancel</Button>
                <Button type="primary"  onClick={handleSave}>Save</Button>
            </HStack>
        )}
    >
        <HStack variant="center" className="form-stack">
            {fields.map((field) => (     
                <div className={field.fullWidth ? 'full-width-input' : 'half-width-input'}>
                    {field.type === 'autocomplete' ? (
                        <Autocomplete
                            {...field}
                            type="text"
                            value={formData[field.id]}
                            onChange={(e) => handleFormChange(e, field.id)}
                            key={field.id}
                        />
                    ) : (
                            <FormInput
                                {...field}
                                value={formData[field.id]}
                                onChange={(e) => handleFormChange(e, field.id)}
                                key={field.id}
                            />
                    )}
                </div>
            ))}
        </HStack>
    </Modal>
  );
};

export default FormDialog;
import React, { useState, useEffect } from 'react';
import { getCurrentUser, signIn , signInWithRedirect, rememberDevice } from '@aws-amplify/auth';
import {  useAuthenticator } from '@aws-amplify/ui-react'

import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import { useMessage } from '../context/MessageContext';
import {
  Alert,
  Divider,
} from '@mui/material';
import { Button, Card, Form, HStack, Stack, Text } from '@arcault-ui/shared-components';
import GoogleIcon from '@mui/icons-material/Google';

const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showMessage, translateMessage } = useMessage();
  
  const {route,authStatus} = useAuthenticator(context => [
    context.route,
    context.authStatus
    ])
  let from = location.state?.from?.pathname || "/";

  // const [formState, setFormState] = useState({ username: '', password: '', rememberMe: false });
  const [user, setUser] = useState(null);
  const [info, setInfo] = useState(null)
  const [error, setError] = useState(null)
  useEffect(() => {
    if (authStatus === "authenticated" && user) {
      navigate('/', from, { replace: true });

    }
  }, [route, navigate, from, user, authStatus]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const authenticatedUser = await getCurrentUser();
        setUser(authenticatedUser);
      } catch (error) {
        setUser(null);
      }
    };
    fetchUser();
  }, []);


  const handleSubmit = async (formState) => {
    // e.preventDefault();
    try {
      console.log("Logging in with ", formState.username, formState.password);
      const signInResponse = await signIn({ username: formState.username, password: formState.password });
      const authenticatedUser = await getCurrentUser();
      // Check if new device metadata is available for remembering the device
      // if (formState.rememberDevice && signInResponse.newDeviceMetadata) {
      //   await rememberDevice();
      //   console.log("Device remembered successfully");
      // }
      setUser(authenticatedUser);
    } catch (error) {
      const userFriendlyMessage = translateMessage(error.message);
      setError(userFriendlyMessage);
      showMessage(userFriendlyMessage, "error")
    }
  };

  const handleGoogleSignIn = () => {
    signInWithRedirect({
      provider: "Google"
    });
  };

  const fields = [
    {
      id: 'username',
      label: 'Email',
      type: 'email',
      fullWidth: true,
      required: true,
    },
    {
      id: 'password',
      label: 'Password',
      type: 'password',
      fullWidth: true,
      required: true,
    },
  ]

  return (
    <>
      <div className="background-layer" />
        <Card className="login-area">
          <Stack>
            <Stack className="headings">
              <HStack className="signin-logo" variant="center">
                <img height="48" src="/logo_flame.png" alt="Logo" />
                <Text variant="h1">Arcault</Text>
              </HStack>
              <Text variant="subheading">Life Planning just got easier</Text>
            </Stack>
            <div className="login-form">
              <Form saveText="Login" updateFunction={handleSubmit} fields={fields} />
            </div>
      
            <HStack variant="spread">
              <Button onClick={() => navigate('/forgotPassword')} type="link">
                Forgot Password?
              </Button>
              <Button onClick={() => navigate('/signup')} type="link" >
                Not a member? Sign up here
              </Button>
            </HStack>
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        {info && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {info}
          </Alert>
        )}
          <Divider sx={{ mt: 3, mb: 2 }}>or</Divider>
          <Button
            type="outline"
            onClick={handleGoogleSignIn}
          >
            <GoogleIcon /> Sign in with Google
          </Button>
          </Stack>
      </Card>
      <Stack>
        <HStack variant="center">
          <Text variant="body" color="textSecondary">
            &copy; 2024 Arcault
          </Text>
        </HStack>
        <HStack variant="center" className="terms">
          <Button onClick={() => navigate("/privacy")} type="link">Privacy Policy</Button>
          |
          <Button onClick={() => navigate("/terms")} type="link">Terms of Service</Button>
        </HStack>
      </Stack>
    </>
  );
};

export default SignIn;

import { Card } from "@arcault-ui/shared-components";
import { Vault } from "@arcault-ui/shared-components";
import useDocuments from "../hooks/useDocuments";

const Documents = () => {
    return (
        <Card title="Documents" className="dashboard documents-dashboard card-no-shadow">
            <Vault 
                label="Estate Planning"
                category="estate_planning"
                useDocuments={useDocuments}
            />
        </Card>
    );
}

export default Documents;
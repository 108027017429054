import setupAxiosWithCredentials from "../utils/setupAxiosWithCredentials";
import { axiosWithCredentials } from "../api/axios";
import Base from './base';

const BASE_URL = 'engage/offer';

class Offers extends Base {
    async accept(id) {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const data = await axiosWithCredentials.post(BASE_URL + '/accept/' + id);
            return data;
        } finally {
            removeInterceptors();
        }
    }
}

export const offersAPI = new Offers(BASE_URL);

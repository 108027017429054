import React, { useState, useEffect, useRef } from 'react';
import { Card, Icon, Button, HStack, Stack, Text, Modal } from '@arcault-ui/shared-components';
import DependantInfo from '../DependantInfo';
import NextStep from '../NextStep';


import { rfpAPI } from '../../../actions/rfp'
import { dataAgentAPI } from '../../../actions/dataAgent';
import RFPEditor from './RFPModal';

const FormRenderer = ({ formData, isOpen, setIsOpen }) => {
  const { form, props } = formData;

  const formComponents = {
    'add-dependent': (
      <Modal title="Add Dependent" isOpen={isOpen}>
        <DependantInfo {...props} />
        <HStack variant="end">
          <Button type="primary" onClick={() => setIsOpen(false)}>Done</Button>
        </HStack>
      </Modal>
     )
  };

  return (
    <>
      {formComponents[form] || <></>}
    </>
  );
};

const capitalizeFirstLetter = (val) => {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

const DashboardNextStep = ({ final_image, title, professionalName, journey, dashboard, priority, setProgress, setStage, loadAssociatedData }) => {
  const [nextStep, setNextStep] = useState();
  const [formOpen, setFormOpen] = useState(false);
  const [draftRFPOpen, setDraftRFPOpen] = useState(false);
  const [rfp, setRFP] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [rfpSent, setRFPSent] = useState(false);

  const FIND_ATTORNEY_LEAD_IN = `Thanks for letting us get to know you. Time to reap the rewards. Take just 2 minutes to connect with our network of ${professionalName}`;
  const ATTORNEY_BUTTON_TEXT = 'Contact our Network';
  const ATTORNEY_BUTTON_HELP_TEXT = `${capitalizeFirstLetter(professionalName)} ready to send you proposals. Non-binding. Non-identifying. And we\'ve already drafted your request.`;
  const WAITING_FOR_ATTORNEY_LEAD_IN = `We've sent your request to our network. Congratulations for taking this step.`
  const WAITING_FOR_ATTORNEY_TEXT = `${capitalizeFirstLetter(professionalName)} are reviewing your request. You\'ll receive proposals soon.`;  

    dataAgentAPI.modifyJourney(journey);

    const loaderRef = useRef({});

      useEffect(() => {
        const getUserJourney = async () => {
          try {
            const nextData = await dataAgentAPI.get();
            setNextStep(nextData.data);
            setProgress(nextData.data?.progress); //  * 100)
            setStage(nextData?.data?.stepId)
            
          } catch (error) {
            console.error("Failed to load user journey:", error);
          }
        }

        const ACTIVE_RFP = true;
        const getRFP = async () => {
          if (ACTIVE_RFP) {
            setIsLoading(true);
            const data = await rfpAPI.draft(journey);
            const { id } = data.data;

            const savedData = await rfpAPI.get(id);
            if (savedData.data) {
              setRFP(savedData.data);
            } else {
              setRFP(data.data);
            }
            setIsLoading(false);
          }
        }
   
        if (!loaderRef?.loaded) {
          getUserJourney();
          getRFP();
          loaderRef.loaded = true;
        }
        
      }, [setProgress, loaderRef]);

      const saveData = async (propertyValue) => {
        const payload = {
          ...nextStep,
          propertyValue
        };

        const savedData = await dataAgentAPI.save(payload);
        if (setProgress) setProgress(savedData.data?.progress); // * 100)
        loadAssociatedData();
        setNextStep(savedData.data);

        return savedData;
      }

      return (
        <Stack variant="end">
          <Card 
            className={`neu-card-low dashboard-next-step ${dashboard ? priority ? 'priority-card-dashbord' : 'dashboard-card' : ' '}`} 
            title={dashboard ? title : (nextStep?.stepId === 'stage_complete' ? <span>Congratulations! <Icon name="celebration" /> </span> : 'Next Step')}
          >
            {nextStep && (
              <FormRenderer formData={nextStep} isOpen={formOpen} setIsOpen={setFormOpen}/>
            )}
            {nextStep && nextStep?.stepId !== 'stage_complete' && <NextStep nextStep={nextStep} setNextStep={setNextStep} saveData={saveData} />}
            {(nextStep && nextStep?.stepId === 'stage_complete') && (
              <div id={`step-complete`} className="step-container">
                <RFPEditor 
                  initialRFP={rfp}
                  isLoading={isLoading}
                  isOpen={draftRFPOpen}
                  setRFPSent={setRFPSent}
                  handleClose={() => setDraftRFPOpen(false)}
                />
                <Stack variant="center" className="dashboard-find-attorney dashboard-next-step-inner">
                  {!rfpSent && rfp.status !== 'PUBLISHED' && <><Text variant="body-large"> {FIND_ATTORNEY_LEAD_IN}</Text>
                  <Card className="find-attorney-button" onClick={() => setDraftRFPOpen(true)}>
                    <HStack variant="spread">
                      <img src={`/${final_image}.png`} alt="Find Attorney" className="dashboard-find-attorney-image" />
                        <Stack className="find-attorney-button-text-box" variant="center">
                          <Text variant="subheading">{ATTORNEY_BUTTON_TEXT}</Text>
                          <Text variant="caption">{ATTORNEY_BUTTON_HELP_TEXT}</Text>
                        </Stack>
                    </HStack>
                  </Card></>}
                  {(rfpSent || rfp.status === 'PUBLISHED') && <><Text variant="body-large"> {WAITING_FOR_ATTORNEY_LEAD_IN}</Text>
                    <HStack variant="spread">
                      <img src={`/${final_image}.png`} alt="Find Attorney" className="dashboard-find-attorney-image" />
                        <Stack className="find-attorney-button-text-box" variant="center">
                          <Text variant="body">{WAITING_FOR_ATTORNEY_TEXT}</Text>
                        </Stack>
                    </HStack>
                  </>}
                </Stack>
              </div>
            )}

          </Card>
        </Stack>
      );
    };

    
    export default DashboardNextStep;

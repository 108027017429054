import React from 'react';
import { 
  Avatar, 
  Button, 
  Card, 
  HStack,
  Modal,
  Text,
} from '@arcault-ui/shared-components';

const CompareProposals = ({ isOpen, offers, onAccept, onClose }) => {
  console.log('offers compare', offers);
  return (
    <Modal key="compare-modal" className="compare-proposals" isOpen={isOpen} title="Compare Proposals" onClose={onClose}>
      <HStack variant="center">
        {(offers || []).map((proposal) => (
          <Card className="offer-card" key={`proposal-compare-${proposal.id}`}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Avatar user={{ avatarUrl: proposal.avatarUrl }} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Text variant="h3">{proposal.businessName}</Text>
            </div>
            <div className="compare-section" >
              <Text variant="body">{proposal.summary}</Text>
            </div>
            <div className="compare-section" >
              <Text variant="body">{proposal.scopeOfWork}</Text>
            </div>
            <div className="compare-section" >
              <Text variant="body">{proposal.pricing}</Text>
            </div>
            {/*<div className="compare-section" >
              <Text variant="subheading">Estimated Cost: ${proposal.pricing}</Text>
              <Text variant="body">{proposal.notesOnCost}</Text>
            </div>*/}
            {/*<div className="compare-section" >
              <Text variant="subheading">Estimated Time: {proposal.estTime}</Text>
              <Text variant="body">{proposal.notesOnTime}</Text>
            </div>*/}
            <div style={{padding: '32px 0px 0px', textAlign: 'right'}} >
              <Button type="primary" onClick={() => onAccept(proposal)}>Accept</Button>
            </div>
          </Card>
        ))}
      </HStack>
    </Modal>
  );
}

export default CompareProposals;

import React, { useState, useEffect } from 'react';
import { Autocomplete, Button, Icon, FormInput, Text, HStack, Stack } from '@arcault-ui/shared-components';
import { CircularProgress } from '@mui/material'

import { useHelp } from '../../context/HelpContext';
import useEnhancedInput from '../../hooks/useEnhancedInput';

import './NextStep.css';

const textTypes = ['text', 'email', 'phone', 'secret', 'money' ];

const anyFormatHelp = 'Any format works. Just type away.';
const anyFormatDate = 'Any format works, e.g. 11th Dec \'24';
const saveHelp = 'Press ↵ to save.';

const PreviousStep = ({ step }) => {
    console.log('previous step', step);
    return (
        <div className="previous-step">
            <div className="previous-step-inner">
                <div className="previous-header">Previous</div>
                <div className="previous-step-info">
                    <Text variant="body">{step.ask || step.description}</Text> <Icon name="Check" />
                </div>
            </div>
        </div>
    )
}

const Step = ({ index, step, saveData }) => {
    const [value, setValue, forcedValue, isValid] = useEnhancedInput({ initialValue: step?.initial || '', type: step?.type });
    const [showOverlay, setShowOverlay] = useState(false);
    const [showCheckMark, setShowCheckMark] = useState(false);
    const [scroll, setScroll] = useState(false);

    const submitResponse = async () => {
        setShowOverlay(true);
        await saveData(forcedValue, step);
        setScroll(true);
        setShowCheckMark(true);
    }

    const handleKeyPress = async (e) => {
        console.log('key press', e.key, isValid, forcedValue);
        if (e.key === 'Enter' && isValid && forcedValue) {
            submitResponse();
        }
    };

    const { setHelpPrompt } = useHelp();

    const handleInputChange = (e) => {
      const eValue = e.target.value;
      step.value = eValue;
      setValue(eValue);
    }

    const handleClick = (e) => {
        saveData(e)
        setScroll(true)
    }

    const { type } = step || {};

    const InputWithButton = ({ children }) => (
        <HStack className={`next-steps-input-group ${forcedValue && forcedValue.length > 0 ? 'next-steps-input-group-active' : null}`}>
        {children}
        {forcedValue && forcedValue.length > 0 && (
            <Button type="primary" onClick={submitResponse}>Save</Button>
        )}
    </HStack>
    )

    return (
            <div id={`step-${index}`} className={`step-container`}>
                {(showOverlay) && (
                    <div className="overlay">
                        <CircularProgress color="success" />
                    </div>
                )}
                {showCheckMark && (
                    <div className="overlay">
                        <Icon name="Check" />
                    </div>
                )}
                <Stack variant="center" className={`dashboard-next-step-inner  ${scroll ? 'card-move-down' : ''}`}>
                    {(type === "boolean") && (
                        <>
                            <Text variant="body">{step.ask || step.description}</Text>
                            <HStack className="button-group" variant="start">
                                <Button onClick={() => handleClick('Yes')} type="outline">Yes</Button>
                                <Button onClick={() => handleClick('No')} type="outline">No</Button>
                            </HStack>
                        </>
                    )}
                    {(type === "button") && (
                        <div>
                            <Button onClick={handleClick} type="secondary">{step.ask}</Button>
                        </div>
                    )}

                    {(type === "date") && (   
                        <InputWithButton>
                            <FormInput
                                autoFocus
                                helpText={forcedValue && isValid && saveHelp}
                                data-testid="arcault-input"
                                label={step.ask || step.description}
                                value={forcedValue}
                                type={'text'}
                                placeholder={anyFormatDate}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyPress}
                            />
                        </InputWithButton>
                    )}

                    {(type === "select") && (
                        <InputWithButton>
                            <Autocomplete
                                autoFocus
                                rightHelp={forcedValue && isValid && saveHelp}
                                placeholder={'Type to filter. Click to dropdown.'}
                                suggestions={step.options}
                                data-testid="arcault-input"
                                label={step.ask || step.description}
                                value={forcedValue}
                                type={type}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyPress}
                            />
                        </InputWithButton>
                    )} 

                    {step?.type === 'ai-analyzed-response' && (
                        <>            
                            <FormInput
                                autoFocus
                                data-testid="arcault-input"
                                label={step.ask === 'What is your middle name, full legal name, phone number, and birthday?' ?
                                    'What\'s your full legal name, phone number, and birthday?' :
                                    (step.ask || step.description)
                                }
                                value={forcedValue}
                                placeholder={anyFormatHelp}
                                type={type === 'ai-analyzed-response' ? 'textarea' : type}    
                                onChange={handleInputChange}
                                onKeyDown={handleKeyPress}
                                helpText={forcedValue && isValid && saveHelp}
                            />
                            <HStack variant="end">
                                <Button type="primary" onClick={submitResponse}>Save</Button>
                            </HStack>
                        </>
                    
                    )}
                    
                    {textTypes.includes(step?.type) && (
                        <InputWithButton>
                            <FormInput
                                autoFocus
                                data-testid="arcault-input"
                                label={step.ask === 'What is your middle name, full legal name, phone number, and birthday?' ?
                                    'What\'s your full legal name, phone number, and birthday?' :
                                    (step.ask || step.description)
                                }
                                value={forcedValue}
                                placeholder={anyFormatHelp}
                                type={type === 'ai-analyzed-response' ? 'textarea' : type}    
                                onChange={handleInputChange}
                                onKeyDown={handleKeyPress}
                                helpText={forcedValue && isValid && saveHelp}
                            />
                        </InputWithButton>
                    )}
                    {step?.description && step?.ask && <Text variant="body">{step?.description} {step?.explainer} <span className="help-link" onClick={() => setHelpPrompt(step.tip)}>Questions?</span></Text>}
                </Stack>
            </div>
    )
}

const NextStep = ({ nextStep, saveData }) => {
    const [steps, setSteps] = useState([]);
    const [hasPrevious, setHasPrevious] = useState(false);

    useEffect(() => {
        console.log('xxnext step', nextStep);
        let newSteps = [...steps.map((s, i) => ({ ...s, past: true, previous: i === steps.length - 1 ? true : false })), nextStep]
        if (newSteps.length > 1) setHasPrevious(true)
        setSteps(newSteps);
    }, [nextStep])



    useEffect(() => {
        console.log('xxscroll action', nextStep);

        const scrollAction = async () => {
            await document.getElementById(`step-${steps.length - 1}`).scrollIntoView({ behavior: 'smooth' });
        }
        if (steps.length > 0) {
            scrollAction()
        }

        /*const newSteps = steps.map((step, index) => {
            return index !== steps.length - 1 ? { ...step, past: true } : {...step}
        })

        setSteps(newSteps);*/
        
    }, [steps])

    return (
        <>
        {steps.length > 0 && steps.map((step, index) => (
            <>
            {!step?.past && <Step hasPrevious={hasPrevious} index={index} step={step} saveData={saveData} />}
            {step?.previous && step?.previous?.id && <PreviousStep step={step} />}
            </>
        ))}
        </>
    );
};

export default NextStep;
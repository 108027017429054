import React, { useEffect, useState } from 'react';

import '../common/dashboard/Dashboard.css';
import { Loading } from '@arcault-ui/shared-components';
import Dashboard from '../common/dashboard/Dashboard';
import InboxDashboard from '../common/inboxDashboard/InboxDashboard';
import { offersAPI } from '../../actions/offers';
import { journeyAPI } from '../../actions/journey';
import { assetsAPI } from '../../actions/assets';
import { liabilitiesAPI } from '../../actions/liabilities';
import { contactsAPI } from '../../actions/contacts';

export const rolesConfig = [
  { name: "Executor", label: "Executor", multiple: false, link: '/profile/estate/executor' },
  { name: "HealthAgent", label: "Health Agent", multiple: false, link: '/profile/estate/healthcareproxy' },
  { name: "FinancialPowerOfAttorney", label: "Financial Power of Attorney", multiple: true, link: '/profile/estate/poa' },
  { name: "Beneficiaries", label: "Beneficiaries", multiple: true, link: '/profile/estate/beneficiaries'},
  { name: "estate-planning-team", label: "", multiple: false, link: '/profile/estate/team' }
];

const DATA_STATE = 'data';
const COMM_STATE = 'comm';


const DUMMY_DATA = false;
const offerMockData = [
  {
    id: 1,
    title: "Estate Planning Offer 1",
    description: "This is a mock description for Estate Planning Offer 1.",
    price: "$1000",
    validUntil: "2023-12-31",
    link: "/offers/1",
    scopeOfWork: "Scope of work for offer 1",
    summary: "Summary of offer 1",
    timing: "Timing details for offer 1",
    pricing: "Pricing details for offer 1",
    companyName: 'Nike',
    avatarUrl: 'https://www.nike.com/favicon.ico'    
  },
  {
    id: 2,
    title: "Estate Planning Offer 2",
    description: "This is a mock description for Estate Planning Offer 2.",
    price: "$1500",
    validUntil: "2023-12-31",
    link: "/offers/2",
    scopeOfWork: "Scope of work for offer 2",
    summary: "Summary of offer 2",
    timing: "Timing details for offer 2",
    pricing: "Pricing details for offer 2",
    companyName: 'Adidas',
    avatarUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Adidas_2022_logo.svg/300px-Adidas_2022_logo.svg.png'
  },
  {
    id: 3,
    title: "Estate Planning Offer 3",
    description: "This is a mock description for Estate Planning Offer 3.",
    price: "$2000",
    validUntil: "2023-12-31",
    link: "/offers/3",
    scopeOfWork: "Scope of work for offer 3",
    summary: "Summary of offer 3",
    timing: "Timing details for offer 3",
    pricing: "Pricing details for offer 3",
    companyName: 'Puma',
    avatarUrl: 'https://upload.wikimedia.org/wikipedia/en/thumb/d/da/Puma_complete_logo.svg/440px-Puma_complete_logo.svg.png'
  }
];

const EstateDashboard = () => {
  const [state, setState] = useState();
  const [offers, setOffers] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [assets, setAssets] = useState([]);
  const [liabilities, setLiabilities] = useState([]);
  const [requiredDocuments, setRequiredDocuments] = useState([]);
  const [requiredTeam, setRequiredTeam] = useState([]);

  const fetchAssets = async () => {
    try {
      const response = await assetsAPI.get();
      setAssets(response.data);
    } catch (error) {
      console.error("Failed to load contacts:", error);
    }
  };

  const fetchLiabilities = async () => {
    try {
      const response = await liabilitiesAPI.get();
      setLiabilities(response.data);
    } catch (error) {
      console.error("Failed to load contacts:", error);
    }
  };


  const fetchContacts = async () => {
    try {
      const response = await contactsAPI.get();
      console.log('response', response);
      
      setContacts(response.data.map(contact => ({
        ...contact,
        name: contact.givenName + ' ' + contact.familyName,
      })));
    } catch (error) {
      console.error("Failed to load contacts:", error);
    }
  };

  const fetchOffers = async () => {
    const offerResponse = await offersAPI.get();
    ((DUMMY_DATA) ? setOffers(offerMockData) : setOffers(offerResponse?.data));
    if (offerResponse?.data?.length > 0) {
      setState(COMM_STATE);
    } else {
      setState(DATA_STATE);
    }
  }

  useEffect(() => {
    fetchJourney();
    loadAssociatedData();
  }, []);

  const fetchJourney = async () => {
    // https://api.dev.arcault.com/ajourney/model/estate_planning
    try {
      journeyAPI.modifyJourney('estate_planning');
      const response = await journeyAPI.get();
      setRequiredDocuments(response?.data?.info?.documents)
      setRequiredTeam(response?.data?.info?.team)
    } catch (error) {
      console.error("Failed to load journey:", error);
    }
  }

  const loadAssociatedData = () => {
    fetchContacts();
    fetchLiabilities();
    fetchAssets();
  }

  useEffect(() => {
    fetchOffers();
    fetchJourney();
    loadAssociatedData();
  }, []);
  
  return <>
    {!state && <Loading />}
    {state === DATA_STATE && (
      <Dashboard
        final_image={'estate_attorney_final'}
        professionalName="attorneys"
        title="Estate Planning"
        journey="estate_planning"
        rolesConfig={rolesConfig}
        contacts={contacts}
        assets={assets} 
        liabilities={liabilities}
        requiredDocuments={requiredDocuments}
        requiredTeam={requiredTeam}
        fetchContacts={fetchContacts}
        loadAssociatedData={loadAssociatedData}
      />
    )}
    {state === COMM_STATE && <InboxDashboard offers={offers} final_image={'estate_attorney_final'} professionalName="attorneys" title="Estate Planning" journey="estate_planning" rolesConfig={rolesConfig} />}
  </>
  
}


export default EstateDashboard;

import setupAxiosWithCredentials from "../utils/setupAxiosWithCredentials";
import { axiosWithCredentials } from "../api/axios";
import Base from './base';

const BASE_URL = 'files';

const genericCall = async (method, url) => {
    const removeInterceptors = setupAxiosWithCredentials();
    try {
        const data = await axiosWithCredentials[method](url);
        return data;
    } finally {
        removeInterceptors();
    }
}

class Files extends Base {
    async getCategory(category) {
        return await genericCall('get', BASE_URL + '/' + category);
    }

    async getUrl(fileKey) {
        const response = await axiosWithCredentials.post('/files/download', {
            fileKey,
        });
        return response?.data?.url;
    }
}

export const filesAPI = new Files(BASE_URL);

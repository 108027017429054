import React, { useState, useEffect } from 'react';
import { signUp, confirmSignUp } from '@aws-amplify/auth';
import { v4 as uuidv4 } from 'uuid';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  Alert,
  Link,
} from '@mui/material';
import { Button, Card, Form, HStack, Stack, Text } from '@arcault-ui/shared-components';

const SignUp = ({ onSignInClick }) => {
  const [formState, setFormState] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    code: '',
  });
  const [message, setMessage] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [confirmationStage, setConfirmationStage] = useState(1); // 1: SignUp, 2: Message, 3: Enter Code
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const getParams = () => {
    const emailFromURL = searchParams.get('email');
    const codeFromURL = searchParams.get('code');
    const idFromURL = searchParams.get('uid');

    return ({ data: { email: emailFromURL, code: codeFromURL, uid: idFromURL }});
  }

  useEffect(() => {
    const emailFromURL = searchParams.get('email');
    const codeFromURL = searchParams.get('code');
    const idFromURL = searchParams.get('uid');

    
    if (emailFromURL && codeFromURL && idFromURL) {
      setFormState({ ...formState, email: emailFromURL, code: codeFromURL, uid: idFromURL});
      setConfirmationStage(3);
    }
  }, [searchParams]);

  const handleSubmit = async (data) => {
    // e.preventDefault();
    setMessage(null);
    setError(null);
    try {
      const username = uuidv4();
      
      await signUp({
        username: username,
        password: data.password,
        options: {
          userAttributes: {
            email: data.email,
            given_name: data.firstName,
            family_name: data.lastName,
          }, 
          clientMetadata: {
            app: "lifeplan",
            appVersion: process.env.REACT_APP_VERSION,
            appSource: process.env.REACT_APP_SOURCE,
            appUrl: process.env.REACT_APP_URL
          }
        }
      });
      setIsSubmitted(true);
      setMessage('Sign-up successful. Please check your email for verification.');
      setConfirmationStage(2); // Show the confirmation message
    } catch (err) {
      setError(err.message || 'Failed to sign up. Please try again.');
    }
  };

  const handleConfirmSignUp = async (data) => {
    // e.preventDefault();
    setMessage(null);
    setError(null);
    try {
      console.log(data.uid)
      console.log(data.code)
      await confirmSignUp({username: data.uid, confirmationCode: data.code});
      setMessage('Confirmation successful! You can now sign in.');
      navigate('/signIn', { state: { message: 'Confirmation successful! You can now sign in.' } })
      
    } catch (err) {
      setError(err.message || 'Failed to confirm. Please try again.');
    }
  };

  return (
    <>
    <div className="background-layer" />
    <Card className="login-area">
      <Stack>
        <Stack className="headings">
          <HStack className="signin-logo" variant="center">
            <img height="48" src="/logo_flame.png" alt="Logo" />
            <Text variant="h1">Arcault</Text>
          </HStack>
          <Text variant="subheading">{confirmationStage === 1 ? 'Create Your Account' : 'Confirm Your Email'}</Text>
        </Stack>  
        {confirmationStage === 1 && (
          <Form updateFunction={handleSubmit} dataCallback={setFormState} fields={[
            {
              id: 'firstName',
              label: 'First Name',
              type: 'text',
              fullWidth: true,
              isRequired: true,
            }, {
              id: 'lastName',
              label: 'Last Name',
              type: 'text',
              fullWidth: true,
              isRequired: true,
            }, {
              id: 'email',
              label: 'Email',
              type: 'email',
              fullWidth: true,
              isRequired: true,
            }, {
              id: 'password',
              label: 'Password',
              type: 'password',
              fullWidth: true,
              isRequired: true,
            }]}
            saveText="Sign Up"
          />
        )}

        {confirmationStage === 2 && (
          <Typography variant="subtitle1">
            A confirmation code has been sent to {formState.email}. Please check your inbox.
          </Typography>
        )}

        {(confirmationStage === 3)  && (
          <Form initialState={{ data: { email: 'test' }, hasEdits: true }} getFunction={getParams} saveText="confirm" updateFunction={handleConfirmSignUp} fields={[{
            id: 'email',
            label: 'Email',
            type: 'email',
            fullWidth: true,
            isRequired: true,
          }, {
            id: 'code',
            label: 'Confirmation Code',
            type: 'text',
            fullWidth: true,
            isRequired: true,
          }]}/>
        )}

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        {message && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {message}
          </Alert>
        )}

        {confirmationStage === 1 && (
          <Typography variant="body2" sx={{ mt: 2 }}>
            Already have an account?{' '}
            <Link onClick={() => navigate('/signin')} sx={{ cursor: 'pointer' }}>
              Sign in here
            </Link>
          </Typography>
        )}
    </Stack>
  </Card>
  <Stack>
    <HStack variant="center">
      <Text variant="body" color="textSecondary">
        &copy; 2024 Arcault
      </Text>
    </HStack>
    <HStack variant="center" className="terms">
      <Button onClick={() => navigate("/privacy")} type="link">Privacy Policy</Button>
      |
      <Button onClick={() => navigate("/terms")} type="link">Terms of Service</Button>
    </HStack>
  </Stack>
</>



  );
};

export default SignUp;

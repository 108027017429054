import React, { useState, useEffect } from 'react';
import './ProposalInbox.css';
import { offersAPI } from '../../../actions/offers';
import CompareProposals from './CompareProposals';

import { 
  Avatar, 
  Button, 
  Card, 
  HStack,
  Icon,
  Stack,
  Text,
  Table,
} from '@arcault-ui/shared-components';

const InboxCard = ({ data, onClick }) => {
  return (
    <Card onClick={() => onClick(data.id)} className="inbox-card neu-card" key={`proposal-${data.id}`}>
      <HStack>
        <Avatar user={{ avatarUrl: data?.avatarUrl }} />
        <Stack gap="0" className="proposal-card-info-stack">
          <Text variant="subheading">{data?.businessName}</Text>
          <Text variant="caption">
            {data?.summary?.length > 120 ? `${data.summary.substring(0, 117)}...` : data.summary}
          </Text>
        </Stack>
      </HStack>
    </Card>
  )
}

const InboxDetail = ({ activeData, setActiveData }) => (
  <Card className="inbox-full-card neu-card" key={`proposal-${activeData.id}`}>
    <HStack>
      <Text variant="h2">
        <div onClick={() => setActiveData(null)}>
          <Icon name="arrow_back_ios" />
        </div> {activeData?.name}
      </Text>
    </HStack>
  </Card>
)

const ProposalInbox = ({ offers }) => {
  const [compareModalOpen, setCompareModalOpen] = useState(false);
  const [hasAccepted, setHasAccepted] = useState(false);
  const [activeData, setActiveData] = useState(null);

  console.log('proposal inbox offers', offers);
  // ...existing code...

  const acceptProposal = (proposal) => {
    // Implement your logic here to accept the proposal
    offersAPI.accept(proposal.id);
    setCompareModalOpen(false);
  }

  const formedOffers = offers?.map(offer => ({
    id: offer.id,
    businessName: offer?.businessProfile?.name,
    avatarUrl: offer?.businessProfile?.avatarUrl,
    summary: offer?.summary,
    scopeOfWork: offer?.scopeOfWork,
    pricing: offer?.pricing,
  }));

  console.log('formedOffers', formedOffers);
  return (
    <>
      <CompareProposals 
        isOpen={compareModalOpen} 
        offers={formedOffers} 
        onAccept={acceptProposal} 
        onClose={() => setCompareModalOpen(false)} 
      />

      <HStack key="proposal-list" variant="start" className={`proposal-inbox proposal-inbox-${offers && offers?.length === 3 ? 'complete' : 'incomplete'}`}>
        <div key="header" className="card-header" style={{ display: 'flex'}}>
          <HStack variant="start">
            <Text variant="h2">{hasAccepted ? 'Agreement' : 'Proposals'}</Text>
            {false && !hasAccepted && offers?.length > 0 && <Button type="outline" onClick={() => setCompareModalOpen(true)}>Compare</Button>}
          </HStack>
        </div>
        {activeData && <InboxDetail activeData={activeData} setActiveData={setActiveData} />}
        {!activeData && (
          <Table 
            defaultViewType="card" 
            rows={formedOffers}
            columns={[
              { id: 'avatarUrl', label: 'Avatar' },
              { id: 'businessName', label: 'Company Name' },
              { id: 'summary', label: 'Summary' },
            ]}
            onClick={(id) => setCompareModalOpen(true)}
            CardView={InboxCard}
          />
        )}
        {!hasAccepted && offers?.length < 3 && offers?.length > 0 && (
          <div data-testid="help-section-wait" className="help-section">
            {`Congratulations, you've received ${offers?.length === 1 ? 'a proposal' : 'proposals'}!`} <b>We recommend you wait a little longer.</b> 
            {offers?.length === 1 && ` Additional professionals have your request in their inbox, and we want you to receive the full benefits of comparison.`}</div>
        )}
      </HStack>
    </>
  )
}

export default ProposalInbox;
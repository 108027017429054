import React, { useEffect, useState, useRef } from 'react';

import { Button, Card, HStack, Icon, Stack, Text } from '@arcault-ui/shared-components'
import FamilyRestroomOutlinedIcon from '@mui/icons-material/FamilyRestroomOutlined';
import { useNavigate } from 'react-router-dom';
import BasicInfo from './BasicInfo';
import JourneySelector from './JourneySelector';
import {useSettings} from '../../context/SettingsContext';

import './Home.css';
import { dataAgentAPI } from '../../actions/dataAgent';

const BASIC_INFO = 'welcome';
dataAgentAPI.modifyJourney(BASIC_INFO);

const Home = (props) => {
  const { settings, updateSettings } = useSettings();
  const [activeJourney, setActiveJourney] = useState(0);
  const [basicInfoNeeded, setBasicInfoNeeded] = useState(false);

  const checkAndRunComplete = (stepId) => {
    if (stepId === 'available_journeys') {
      setBasicInfoNeeded(false);
      updateSettings({ basicInfoComplete: true });
    } else {
      setBasicInfoNeeded(true);
    }
  }

  const getWelcomeStatus = async () => {
    try {
      const nextData = await dataAgentAPI.get()      
      checkAndRunComplete(nextData.data.stepId)
    } catch (error) {
      console.error("Failed to load user journey:", error);
    }
  }

  useEffect(() => {
    if(!settings.basicInfoComplete) {
      getWelcomeStatus();
    }
  }, [settings]);


  return (
    <>
      <Card className={`overview-inner card-no-shadow overview-inner-${activeJourney}`}>
        <HStack>
          <div className="dashboard-left">
            <BasicInfo isOpen={basicInfoNeeded} setBasicInfoNeeded={setBasicInfoNeeded} />         
            <JourneySelector />
          </div>
          <img alt="family_photo" className="family-photo" src="family_fixed.png" />
        </HStack>
      </Card>
    </>
  );
}

export default Home;
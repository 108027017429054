import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { Button, FormInput, HStack } from '@arcault-ui/shared-components';
import './JsonEditorPage.css';

const JsonEditor = ({ searchValue, expandedAll, jsonData, filter, setJsonData }) => {
  const [expandedKeys, setExpandedKeys] = useState({});

useEffect(() => {
    if (searchValue && searchValue.length > 0) expandAllKeys(jsonData);
}, [searchValue])

/*useEffect(() => {
    const expandAllKeys = (data, path = '') => {
        const keys = {};
        Object.keys(data).forEach((key) => {
            const currentPath = path ? `${path}.${key}` : key;
            keys[currentPath] = true;
            if (typeof data[key] === 'object' && data[key] !== null) {
                Object.assign(keys, expandAllKeys(data[key], currentPath));
            }
        });
        return keys;
    };

    const keys = expandAllKeys(jsonData);
    setExpandedKeys(keys);
}, [jsonData]);*/

const expandAllKeys = (data, path = '') => {
    const keys = {};
    Object.keys(data).forEach((key) => {
      const currentPath = path ? `${path}.${key}` : key;
      keys[currentPath] = expandedAll;
      if (typeof data[key] === 'object' && data[key] !== null) {
        Object.assign(keys, expandAllKeys(data[key], currentPath));
      }
    });
    return keys;
  };

  useEffect(() => {
    const keys = expandAllKeys(jsonData);
    console.log('keys', keys)
    setExpandedKeys(keys);
  }, [expandedAll]); // If you add jsonData here, it will collapse on any edit.

  const toggleExpand = (key) => {
    setExpandedKeys((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleInputChange = (path, value) => {
    const keys = path.split('.');
    const updatedJson = { ...jsonData };
    let current = updatedJson;

    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        current[key] = value;
      } else {
        current = current[key];
      }
    });

    setJsonData(updatedJson);
  };

  const renderJson = (data, path = '', level = 0, filter) => {
    console.log('filter', filter)
    return Object.keys(data).map((key) => {
      const currentPath = path ? `${path}.${key}` : key;
    const filterPath = currentPath.replace(/\.\d+\./g, '.');
      
      const value = data[key];
      const isObject = typeof value === 'object' && value !== null;
      const isArray = Array.isArray(value);
      const isObjectOrArray = isObject || isArray;

    let searchPositive = true;
    /*if (searchValue ) {
        searchPositive = false;
        if (currentPath.indexOf(searchValue) !== -1) searchPositive = true;
        if (value && value.indexOf(searchValue) !== -1) searchPositive = true;
        }*/

      if (filter[filterPath] === false || !searchPositive){
        return null;
      }

      return (
        <Box className={'json-level-' + level} key={currentPath} marginBottom={4} style={{ paddingLeft: 16 }}>{/*level*/}
          <HStack direction="row" alignItems="center">
            <div className={!isNaN(key) ? 'json-key-item' : 'json-key'}>
              {!isNaN(key) ? (
                <div className="array-item">item {parseInt(key)}</div>
              ) : (
                <FormInput value={key} onChange={(e) => handleInputChange(currentPath, e.target.value)} />
              )}
            </div>
            {isObjectOrArray ? (
                <Button
                    style={{ paddingLeft: isObjectOrArray && !isNaN(key) ? 16 : 0 }} /*level*/
                    className={'array-label expand-button json-level-button-' + level}
                    onClick={() => toggleExpand(currentPath)}
                >
                    {isArray ? 'Array' : 'Object'} {expandedKeys[currentPath] ? '-' : '+'}
                </Button>
            ) : (
              <div className="json-value">
                <FormInput
                  type={value && value.length > 28 ? 'textarea' : 'text'}
                  value={value}
                  placeholder="empty"
                  onChange={(e) => handleInputChange(currentPath, e.target.value)}
                />
              </div>
            )}
          </HStack>
          {isObject && expandedKeys[currentPath] && (
            <Box marginLeft={4}>{renderJson(value, currentPath, level + 1, filter)}</Box>
          )}
        </Box>
      );
    });
  };

  return <>{renderJson(jsonData, '', 0, filter)}</>;
};

export default JsonEditor;
import PropTypes from 'prop-types';
import './Tab.css';

export const Tab = ({ children, activeTab, label, onClick }) => {
  const handleClick = () => {
    onClick(label);
  };

  return (
    <div
      className={`tab-item ${activeTab === label ? 'active' : ''}`}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  activeTab: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const TabList = ({ children }) => {
  return <div className="tab-list">{children}</div>;
};

TabList.propTypes = {
  children: PropTypes.node.isRequired,
};

export const TabPanel = ({ children, activeTab, label }) => {
  return activeTab === label ? <div className="tab-panel">{children}</div> : null;
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  activeTab: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
import Base from './base';

const BASE_URL = '/ajourney/datagent';

class DataAgent extends Base {
    modifyJourney(journey) {
        this.base_url = BASE_URL + '/' + journey;
    }

}

export const dataAgentAPI = new DataAgent(BASE_URL + '/welcome');

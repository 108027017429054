
import React from 'react';
import PropTypes from 'prop-types';
import { Progress, Card, Text } from '@arcault-ui/shared-components';
import './DashboardProgress.css'; // Assuming you have a CSS file for additional styles

const DashboardProgress = ({ journey, progress, stage, title }) => {
    // Validating the progress value to be between 0 and 100
    if (typeof progress !== 'number' || progress < 0 || progress > 100) {
      console.error("Invalid 'progress' value. It should be a number between 0 and 100.");
      // return null;
    }
  
    return (
        <Card className="dashboard-progress card-no-shadow">
            {/*<img src={`/${journey}_icon.png`} alt={journey} />*/}
            <Text variant="h1">{title}</Text>
            <div className="progress-container">
              <div className="progress-info">
                <div className="progress-label">Progress</div>
                <div className="progress-amt">{progress}%</div>
              </div>
              <Progress 
                  variant="pointed-right" 
                  height="64px"
                  color="accent"
                  value={progress} 
                  max={100}
              />
              <div className="progress-stage">
                <Text variant="caption">stage: {stage.replace(/_/g, ' ')}</Text>
              </div>
            </div>
        </Card>
    );
  };
  
  // Defining PropTypes for our component
  DashboardProgress.propTypes = {
    // progress should be a number between 0 and 100
    progress: PropTypes.number.isRequired,
  };
  
  // Default Props
  DashboardProgress.defaultProps = {};
  
  export default DashboardProgress;
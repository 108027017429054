import React, { useState, useEffect } from 'react';
import './ProposalInbox.css';
import { offersAPI } from '../../../actions/offers';
import CompareProposals from './CompareProposals';

import { 
  Avatar, 
  Button, 
  Card, 
  HStack,
  Icon,
  Stack,
  Text,
  Table,
} from '@arcault-ui/shared-components';

const InboxCard = ({ data, onClick }) => (
  <Card onClick={() => onClick(data.id)} className="inbox-card neu-card" key={`proposal-${data.id}`}>
    <HStack>
      <Avatar user={{ avatarUrl: data?.avatarUrl }} />
      <Stack gap="0" className="proposal-card-info-stack">
        <Text variant="subheading">{data?.firstName} {data?.lastName}</Text>
        <Text variant="caption">
          {data?.summary?.length > 120 ? `${data.summary.substring(0, 117)}...` : data.summary}
        </Text>
      </Stack>
    </HStack>
  </Card>
)

const InboxDetail = ({ activeData, setActiveData }) => (
  <Card className="inbox-full-card neu-card" key={`proposal-${activeData.id}`}>
    <HStack>
      <Text variant="h2">
        <div onClick={() => setActiveData(null)}>
          <Icon name="arrow_back_ios" />
        </div> 
      </Text>
      <Text variant="h2">{activeData?.firstName} {activeData?.lastName}</Text>
    </HStack>
  </Card>
)

const Inbox = ({ contacts }) => {
  console.log('contacts', contacts)
  const [compareModalOpen, setCompareModalOpen] = useState(false);
  const [hasAccepted, setHasAccepted] = useState(false);
  const [activeData, setActiveData] = useState(null);

  // ...existing code...

  const acceptProposal = (proposal) => {
    // Implement your logic here to accept the proposal
    offersAPI.accept(proposal.id);
    setCompareModalOpen(false);
  }
// handleSort, columns, displayValue, actions, isLoading, rows, onClick, sortConfig
  return (
    <>
      <div key="proposal-list" className={`proposal-inbox proposal-inbox-${contacts && contacts?.length === 3 ? 'complete' : 'incomplete'}`}>
        <div key="header" className="card-header" style={{ display: 'flex'}}>
          <HStack variant="start">
            <Text variant="h2">{hasAccepted ? 'Agreement' : 'Proposals'}</Text>
          </HStack>
        </div>
        {activeData && <InboxDetail activeData={activeData} setActiveData={setActiveData} />}
        {!activeData && (
          <Table 
            defaultViewType="card" 
            rows={contacts}
            columns={[
              { id: 'firstName', label: 'First Name' },
              { id: 'lastName', label: 'Last Name' },

            ]}
            onClick={(id) => {
              console.log('clicker', id)
              setActiveData(contacts.find(c => c.id === id))
            }}
            CardView={InboxCard}
          />
        )}
      </div>
    </>
  )
}

export default Inbox;
import React from 'react';
import { Typography, Box } from '@mui/material';
import { Card } from '@arcault-ui/shared-components';

const Privacy = () => {
  return (
    <>
    <div className="background-layer" />
    <Card className="terms-card">
      <Box>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to Arcault’s Privacy Policy. Your privacy is very important to us. This policy explains how we collect, use, disclose, and safeguard your information when you use our digital life planning platform. Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.
        </Typography>

        <Typography variant="h5" gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect information that you provide to us directly when you use our platform, such as:
        </Typography>
        <ul>
          <li><strong>Personal Information:</strong> Name, email address, phone number, and other identifying information.</li>
          <li><strong>Financial Information:</strong> Details of your assets, liabilities, and other financial data necessary for estate planning.</li>
          <li><strong>Professional Information:</strong> Information related to your legal, financial, or insurance professionals, if you choose to connect with them through our platform.</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use your information to provide, operate, and maintain our services. Specifically, we may use your information to:
        </Typography>
        <ul>
          <li>Create and manage your account.</li>
          <li>Facilitate estate planning and financial management services.</li>
          <li>Connect you with legal, financial, and insurance professionals based on your preferences.</li>
          <li>Improve and personalize your experience on our platform.</li>
          <li>Communicate with you, including responding to your inquiries and providing updates.</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          3. Sharing Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We do not sell your personal information to third parties. We may share your information with:
        </Typography>
        <ul>
          <li><strong>Service Providers:</strong> Third-party vendors and other service providers that perform services on our behalf, such as data storage and analytics.</li>
          <li><strong>Legal Professionals:</strong> With your consent, we may share your information with attorneys, CPAs, or other professionals to facilitate your estate planning needs.</li>
          <li><strong>Compliance:</strong> If required by law, we may disclose your information to comply with legal obligations or protect our rights.</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          4. Data Security
        </Typography>
        <Typography variant="body1" paragraph>
          We use reasonable and appropriate security measures to protect your information from unauthorized access, use, or disclosure. However, please be aware that no security measures are perfect or impenetrable, and we cannot guarantee absolute security.
        </Typography>

        <Typography variant="h5" gutterBottom>
          5. Your Rights
        </Typography>
        <Typography variant="body1" paragraph>
          Depending on your location, you may have certain rights regarding your personal information, including the right to access, update, or delete your data. To exercise these rights, please contact us at privacy@arcault.com.
        </Typography>

        <Typography variant="h5" gutterBottom>
          6. Changes to This Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the updated policy on our website.
        </Typography>

        <Typography variant="h5" gutterBottom>
          7. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Policy, please contact us at privacy@arcault.com.
        </Typography>
      </Box>
    </Card>
    </>
  );
};

export default Privacy;
